import React from 'react'
import Error from './Error';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Bir sonraki render'da son çare arayüzünü göstermek için
		// state'i güncelleyin.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// Hatanızı bir hata bildirimi servisine de yollayabilirsiniz.
		// console.log(error);
	}

	render() {
		if (this.state.hasError) {
			// İstediğiniz herhangi bir son çare arayüzünü render edebilirsiniz.
			return <Error
				title='Hay Aksi!'
				text='Bir şeyler ters gitti ve bir hata ile karşılaştık.'
				retry={true}
				callback={() => { window.location.reload() }}
			/>;
		}

		return this.props.children;
	}
}

export default ErrorBoundary