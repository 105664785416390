// ** Reducers Imports
import auth from "./auth"
import layout from "./layout"
import navbar from "./navbar"
import navhistory from "./navhistory"
import application from "../views/application/store"

const rootReducer = { auth, navbar, layout, navhistory, application }

export default rootReducer
