// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const navhistorySlice = createSlice({
	name: 'navhistory',
	initialState: {
		keys: []
	},
	reducers: {
		addKey: (state, action) => {
			state.keys = [...state.keys, action.payload]
		}
	}
})

export const { addKey } = navhistorySlice.actions

export default navhistorySlice.reducer
