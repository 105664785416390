import { initializeApp } from "firebase/app"
import { getMessaging, getToken, onMessage } from "firebase/messaging"
import toast from "react-hot-toast";

const firebaseConfig = {
	apiKey: "AIzaSyACjIJVWASAQjF-ESq6y6qrTrQMF6k8ZOY",
	authDomain: "emirdagburs.firebaseapp.com",
	projectId: "emirdagburs",
	storageBucket: "emirdagburs.appspot.com",
	messagingSenderId: "404365545797",
	appId: "1:404365545797:web:91d0d6f7da28a343f2ae2c",
	measurementId: "G-TC4R6MKC08"
};

const app = initializeApp(firebaseConfig)
const vapidKey = 'BDFkpnl9q5rTQN0XNIkf1-u2UaTDq-9WJpek4tkAcoMFB-L-uxG3NyXyscP_C290UvCrN1va2h3T0tEXV2fPoXE'

const initMessaging = () => {
	const messaging = getMessaging(app)
	getToken(messaging, { vapidKey }).then((currentToken) => {
		if (currentToken) {
			/* console.log(currentToken) */
			onMessage(messaging, (payload) => {
				toast(<div><strong>{payload.notification?.title}</strong><br />{payload.notification?.body}</div>)
			})

		}
	})
}

const initApp = () => {

	if ('Notification' in window) {
		if (Notification?.permission === 'granted') {
			initMessaging()
		}/*  else {
			Notification?.requestPermission().then((permission) => {
				if (permission === 'granted') {
					initMessaging()
				}
			})
		} */
	}



}


export { initApp, app }