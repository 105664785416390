import React from "react";
import { AlertTriangle } from "react-feather";
import { Alert, Button } from "reactstrap";


const Error = (props) => {

	
	const status = props.status || 0
	const retry = props.retry || false
	const callback = props.callback || (() => { })
	const buttonTitle = props.buttonTitle || 'Tekrar Dene'
	let title = props.title || ''
	let text = props.text || ''

	if (title == '') {
		switch (status) {
			case 403: title = 'Yetkin Yok!'
				break
			case 404: title = 'Sayfa Bulunamadı!'
				break
			case 500: title = 'Hay Aksi!'
				break
			default: title = 'Hata!'
		}
	}

	if (text == '') {
		switch (status) {
			case 403: text = 'Bu sayfayı görüntüleme yetkin yok.'
				break
			case 404: text = '🔍 Aradığınız sayfa bulunamadı.'
				break
			case 500: text = '🥴 Üzgünüz, bir hata oluştu.'
				break
			default: text = '🥴 Üzgünüz, bir hata oluştu.'
		}
	}

	return (
		<div>
			<Alert color={status === 404 ? 'warning' : 'danger'} style={{ maxWidth: '600px', margin: '0 auto' }}>
				<h4 className='alert-heading d-flex align-items-center'><AlertTriangle size={20} className='me-50' /> { title }</h4>
				<div className='alert-body'>
					<p>
						{text}
					</p>
					{retry &&
						<div className="text-center mt-2">
							<Button color='secondary' outline onClick={callback}>{buttonTitle}</Button>
						</div>
					}
				</div>
			</Alert>
		</div>
	)


}

export default Error