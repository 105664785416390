// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"

export const applicationSlice = createSlice({
	name: "application",
	initialState: {
		demographicInfo: null,
		educationInfo: null,
		documents: null,
		result: null,
	},
	reducers: {
		setDemographicInfo: (state, action) => {
			state.demographicInfo = action.payload
		},
		setEducationInfo: (state, action) => {
			state.educationInfo = action.payload
		},
		setDocuments: (state, action) => {
			state.documents = action.payload
		},
		setResult: (state, action) => {
			state.result = action.payload
			state.demographicInfo = null
			state.educationInfo = null
			state.documents = null
		},
		clearApplication: (state) => {
			state.demographicInfo = null
			state.educationInfo = null
			state.documents = null
		},
		clearResult: (state) => {
			state.result = null
		},
	},
})

export const { setDemographicInfo, setEducationInfo, setDocuments, setResult, clearApplication, clearResult } = applicationSlice.actions

export default applicationSlice.reducer
